




















import {
  Component,
  Prop,
  Vue
} from "vue-property-decorator";

@Component({
  name: 'global-search',
})
export default class GlobalSearch extends Vue {
  @Prop({}) private canEdit!: boolean
  private isEdit: boolean = false
  private keyword: string = ''

  /**
   * @func 输入框聚焦
   */
  private onFocus() {
    const refs: any = this.$refs.searchBox
    this.$nextTick(() => {
      refs.focus()
    })
  }

  /**
   * @func 取消搜索
   */
  private cancelSearch() {
    this.keyword = ''
    this.isEdit = false
    this.$emit('clear')
  }

  /**
   * @func 展示输入框
   */
  private showInput() {
    this.isEdit = true
    this.onFocus()
  }

  /**
   * @func 跳转到搜索页面
   */
  private toSearch() {
    this.$emit('jump')
  }

  /**
   * @func 进行搜索
   */
  private doSearch() {
    this.$emit('search', this.keyword)
  }
}
