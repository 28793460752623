













import {
  api
} from "@/api";
import {
  WechatModule
} from "@/store/modules/wechat";
import {
  Component,
  Vue
} from "vue-property-decorator";
import Search from '@/components/global/search.vue'

@Component({
  name: 'search-page',
  components: {
    Search
  }
})
export default class SearchPage extends Vue {
  private list: any = []
  private defChatAvatar: string = 'https://oss.guojiangmedia.com/wxbackend/default-avatar.png'

  mounted() {
    this.$nextTick(() => {
      const refs: any = this.$refs.globalSearch
      refs.showInput()
    })
  }

  private get deviceId(): number {
    return WechatModule.deviceId
  }

  /**
   * @func 获取好友列表
   */
  private async searchResult(keyword ? : string) {
    if (!keyword) {
      this.list = []
      return
    }
    this.list = []
    try {
      const param: any = {
        robot_id: this.deviceId,
        offset: 1,
        num: 100,
        keyword: keyword || ''
      }
      const res: any = await api.getFriendsList(param)
      if (res.friends && res.friends.length) {
        res.friends.map((val: any) => {
          this.list.push(val)
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @func 点击好友
   */
  private chooseFri(item: any) {
    WechatModule.changeFriendId(item.friend_detail.id)
    WechatModule.changeFriendStrId(item.friend_detail.wxid)
    WechatModule.changeFriendInfo(JSON.stringify(item.friend_detail))
    WechatModule.changeIsChatRoom(false)
    this.$router.push({
      name: 'Chat'
    })
  }
}
